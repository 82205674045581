import { SandpackProvider, defaultDark } from "@codesandbox/sandpack-react";
import { Sandpack } from "../Sandpack/Sandpack";
import {
  AnimaFiles,
  prepareFilesForPlayground,
} from "../../utilities/mapAnimaFilesToCSBFiles";
import { deriveConfigFromFiles } from "../../utilities/deriveConfigFromFiles";
import { useSearchParams } from "react-router-dom";
import { useContext, useEffect, useMemo } from "react";
import { useTracking } from "../../tracking";
import { SandpackUIContext } from "../../providers/SandpackUIContext";
import { getIsPreview } from "../../utilities/getIsPreview";

interface Props {
  data?: {
    files?: AnimaFiles;
  };
}

export function SandpackWrapper(props: Props) {
  const { data } = props;
  const [searchParams] = useSearchParams();
  const { trackEvent } = useTracking();

  const { onChange } = useContext(SandpackUIContext);

  // The useMemo ensures we don't force Sandpack to restart when the component re-renders
  const files = useMemo(
    () => prepareFilesForPlayground(data?.files),
    [data?.files],
  );

  const templateConfig = deriveConfigFromFiles(files);
  const defaultActiveFile = searchParams.get("file");

  const isPreview = getIsPreview();
  useEffect(() => {
    onChange("isPreview", isPreview);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreview]);

  if (defaultActiveFile) {
    templateConfig.options.activeFile = defaultActiveFile;
  }

  useEffect(() => {
    trackEvent("playground.start", {
      url: window.location.href,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SandpackProvider files={files} theme={defaultDark} {...templateConfig}>
      <Sandpack />
    </SandpackProvider>
  );
}
