import { useContext } from "react";
import { DownloadProjectContext } from "../../providers/DownloadProject";
import { useParams } from "react-router-dom";
import downloadIcon from "../../icons/download.svg";

export function DownloadButton() {
  const { isDownloading } = useContext(DownloadProjectContext);
  const { id } = useParams<{ id: string }>();

  return (
    <button
      data-size="small"
      data-variant="link"
      aria-label="Download Project"
      data-balloon-pos="down-right"
      disabled={isDownloading || !id}
      onClick={() => {
        document.dispatchEvent(new CustomEvent("playground:download"));
      }}
    >
      <img alt="" src={downloadIcon} />
    </button>
  );
}
