import { PropsWithChildren, createContext, useState } from "react";

interface GenerationSessionContextType {
  teamId?: string;
  userId?: string;
  userEmail?: string;
  update: (fields: Partial<GenerationSessionContextType>) => void;
}

export const GenerationSessionContext =
  createContext<GenerationSessionContextType>({
    update: () => null,
  });

export function GenerationSessionProvider({ children }: PropsWithChildren) {
  const [state, setState] = useState({});

  const update = (fields: Partial<GenerationSessionContextType>) => {
    setState({
      ...state,
      ...fields,
    });
  };

  return (
    <GenerationSessionContext.Provider
      value={{
        ...state,
        update,
      }}
    >
      {children}
    </GenerationSessionContext.Provider>
  );
}
