import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getGenerationSession } from "../../api/getGenerationSession";
import { ErrorPage } from "../ErrorPage/ErrorPage";
import { useContext } from "react";
import { DevtoolsContext } from "../SandpackDevTools/SandpackDevTools";
import { LoadingPage } from "../LoadingPage/LoadingPage";
import { GenerationSessionContext } from "../../providers/GenerationSession";
import { SandpackWrapper } from "../SandpackWrapper/SandpackWrapper";

export function AnimaCodeEditor() {
  const { id } = useParams<{ id: string }>();
  const { environment } = useContext(DevtoolsContext);

  const { update } = useContext(GenerationSessionContext);

  const { isLoading, isError, data } = useQuery({
    queryKey: [id, environment],
    queryFn: () => getGenerationSession(id!, { environment }),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    onSuccess({ teamId, userEmail, userId }) {
      update({
        teamId,
        userEmail,
        userId,
      });
    },
  });

  if (isLoading) return <LoadingPage />;

  if (isError) return <ErrorPage />;

  return <SandpackWrapper data={data} />;
}
