import { Environment, endpoints } from "../config/endpoints";
import { AnimaFiles } from "../utilities/mapAnimaFilesToCSBFiles";

type Params = {
  environment: Environment;
};

interface getGenerationSessionResponse {
  files: AnimaFiles;
  userId: string;
  userEmail: string;
  teamId: string;
}

export async function getGenerationSession(
  sandboxId: string,
  { environment }: Params,
): Promise<getGenerationSessionResponse> {
  const apiUrl = endpoints[environment];
  const endpointUrl = `${apiUrl}/generation_sessions/${sandboxId}/sandpack`;

  const generationSessionResponse = await fetch(endpointUrl);

  if (!generationSessionResponse.ok) {
    throw new Error(generationSessionResponse.statusText);
  }

  const {
    url,
    user_id: userId,
    user_email: userEmail,
    team_id: teamId,
  } = await generationSessionResponse.json();

  const s3UrlRes = await fetch(url, {
    cache: "no-store",
  });

  if (!s3UrlRes.ok) {
    throw new Error(s3UrlRes.statusText);
  }

  const s3UrlData = await s3UrlRes.json();

  return {
    files: s3UrlData.files,
    userId,
    userEmail,
    teamId,
  };
}
